import React, { useEffect, useState } from "react";

// library
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
//import { Home } from "../../pages/Home/Home";
//import Login from "../../pages/Login/Login";
//import { JoinWaitList } from "../../pages/JoinWaitList/JoinWaitList";
//import { JoinWaitListThanks } from "../../pages/JoinWaitListThanks/JoinWaitListThanks";
import { BeforeReleaseDate } from "../../pages/BeforeReleaseDate/BeforeReleaseDate";
//import { AfterReleaseDate } from "../../pages/AfterReleaseDate/AfterReleaseDate";
//import { JoinWaitListApprove } from "../../pages/JoinWaitListApprove/JoinWaitListApprove";
//import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";

import ToasterContainer from "../Toaster/Toaster";
import { routes } from "../../../utils/routes";
import { LogoLoader } from "../LogoLoader/LogoLoader";

export const App = () => {
  const [loading, setLoading] = useState(true);
  /*

  const str_date = process.env.REACT_APP_RELEASE_AT;

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));

  const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date = new Date(Date.UTC(year, month, day, hour, minute, second)).getTime()

  const now = Date.now()

  const is_live = {
    date_epoch: date,
    now_epoch: now
  }

  const date_check = is_live.now_epoch > is_live.date_epoch
  */
 
  const onPageLoad = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  };

  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      {loading && <LogoLoader />}
      <BrowserRouter>
        <Routes> 
            <>
              <Route path={routes.home} element={<BeforeReleaseDate />}>
                  <Route index element={<BeforeReleaseDate />} />
              </Route>
            </>
        </Routes>
        <ToasterContainer />
      </BrowserRouter>
    </>
  );
};
