import { createStore, createTypedHooks } from 'easy-peasy';

import model from './models/mainModel';

const { useStoreActions, useStoreState, useStoreDispatch } = createTypedHooks();

export { useStoreActions, useStoreDispatch, useStoreState };

const store = createStore(model);

export default store;
