import { persist } from 'easy-peasy';
import { authentication } from './authModal';
import { waitlist } from './waitlistModel';

const model = {
    authentication: persist(authentication, {
        storage: 'localStorage',
    }),
    waitlist: waitlist
};

export default model;
