

function lastSunday(month, year) {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}

function isBST(date) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

function getOrdinalNum(number) {
  let selector;

  if (number <= 0) {
    selector = 4;
  } else if ((number > 3 && number < 21) || number % 10 > 3) {
    selector = 0;
  } else {
    selector = number % 10;
  }

  return number + ['th', 'st', 'nd', 'rd', ''][selector];
};

export function checkLive(date_string) {

  const str_date = date_string

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second))

  let timezone = '(GMT)'

  if (isBST(date_)) {
    //hour = 1
    timezone = '(BST/GMT+1)'
  }

  const release_date = new Date(Date.UTC(year, month, day, hour, minute, second))

  const date = release_date.getTime()

  const release_date_str = getOrdinalNum(release_date.getDate()) + ' ' + monthNames[release_date.getMonth()] + ' ' + release_date.getFullYear() + ' ' + release_date.toLocaleString('en-US', { hour: 'numeric', hour12: true }).toLowerCase().replace(' ', '') + ' ' + timezone

  const now = Date.now()

  return {
    date_epoch: date,
    now_epoch: now,
    release_date: release_date_str
  }

}
