import React from 'react';

// libs
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';

// components
import { App } from "./components/common";

// assets
import './styles/index.scss';

import graphqlClient from './graphql/client';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <ApolloProvider client={graphqlClient}>
        <App />
      </ApolloProvider>
    </StoreProvider>
  </React.StrictMode>
);