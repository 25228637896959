import { useState, useEffect } from "react";
import ReactHowler from "react-howler";

import styles from "./ImagePlayer.module.scss";
import play from "../../../images/icons/playWithoutCircle.svg";
import pause from "../../../images/icons/pause.svg";

export const ImagePlayer = ({preview_url, cover_url}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      window.gtag('event', 'waitlist_play_preview', {});
    }

  }, [isPlaying])

  return (
    <div className={styles.player_container}>
      <img className={styles.img} src={cover_url} alt="song cover" />
      <div className={styles.player}>
        <img
          className={styles.play}
          src={isPlaying ? pause : play}
          alt=""
          height={isPlaying ? 60 : 60}
          width={isPlaying ? 60 : 60}
          onClick={() => setIsPlaying((prev) => !prev)}
        />
        {preview_url && (
          <ReactHowler
            src={preview_url}
            playing={isPlaying}
            onEnd={() => setIsPlaying(false)}
            loop={false}
          />
        )}
      </div>
    </div>
  );
};
