import React from 'react';

// assets
import styles from './VideoModal.module.scss';
import close from '../../../images/icons/close.svg';

export const VideoModal = ({ src, setShowVideo }) => {
  return (
    <div className={styles.videoModal}>
      <div onClick={() => setShowVideo(false)} className={styles.overlay} />
      <div className={styles.content}>
        <div className={styles.close} onClick={() => setShowVideo(false)}>
          <img src={close} alt='' />
        </div>
        <iframe src={`${src}?autoplay=1&mute=0`} frameBorder={0} title='Video' allowFullScreen />
      </div>
    </div>
  )
}