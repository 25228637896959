import { gql } from '@apollo/client';

export const AUTHENTICATE_MUTATION = gql`
  mutation AUTHENTICATE($username: Username!, $password: String!) {
    authenticate(input: { username: $username, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
      }
    }
  }
`;

export const AUTHENTICATE_EMAIL_MUTATION = gql`
  mutation AUTHENTICATE($email: Email!, $password: String!) {
    authenticateEmail(input: { email: $email, password: $password }) {
      auth {
        accessToken
        refreshToken
        isEmailConfirmed
        redirectToGiveaway
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      auth {
        accessToken
        refreshToken
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: Email!) {
    userSendResetPassword(input: { emailValue: $email }) {
      responseType {
        status
        message
      }
    }
  }
`;