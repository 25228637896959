import { action } from 'easy-peasy';

import jwtDecode from "jwt-decode";


export const authentication = {
    isAuthenticated: false,
    isEmailConfirmed: false,
    redirectToGiveaway: false,
    accessToken: null,
    refreshToken: null,
    emailAddress: null,
    expiryTime: null,
    isArtist: false,
    slug: null,
    publicKey: { keyId: '', publicKey: '' },
    setAccessToken: action((state, payload) => {
        state.accessToken = payload;
        state.isAuthenticated = true;

        const decoded = jwtDecode(
            payload
        );

        if ((decoded).role === "app_artist") {
            state.isArtist = true
        }

    }),
    setRefreshToken: action((state, payload) => {
        state.refreshToken = payload;
        state.isAuthenticated = true;
    }),
    setExpiryTime: action((state, payload) => {
        state.expiryTime = payload;
        state.isAuthenticated = true;
    }),
    setPublicKey: action((state, payload) => {
        state.publicKey = payload;
    }),
    setEmailConfirm: action((state, payload) => {
        state.isEmailConfirmed = payload;
    }),
    setEmailAddress: action((state, payload) => {
        state.emailAddress = payload;
    }),
    setSlug: action((state, payload) => {
        state.slug = payload;
    }),
    setRedirectToGiveaway: action((state, payload) => {
        state.redirectToGiveaway = payload;
    }),
    setLogout: action((state) => {
        state.accessToken = null;
        state.emailAddress = null;
        state.refreshToken = null;
        state.expiryTime = null;
        state.isAuthenticated = false;
        state.isArtist = false;
        state.slug = null;
    }),
};
