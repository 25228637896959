import React from "react";

// assets
import styles from './LogoLoader.module.scss';
import logo from '../../../images/icons/songbits.svg';

export const LogoLoader = () => {

  return (
    <div className={styles.logoLoader}>
      <img src={logo} alt='' />
    </div>
  );
};